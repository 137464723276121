.home {
  .card-body {
    padding-bottom: 0px;
  }

  .project-card {
    margin-bottom: 20px;
    height: 260px;

    @include media-breakpoint-up(lg) {
      & {
        max-width: 300px;
      }
    }
  }

  &__map {
    width: 700px;
    height: 600px;
    display: none;

    @include media-breakpoint-up(md) {
      &.active {
        display: block;
      }
    }
  }
}
