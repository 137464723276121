.ged {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__content {
    flex: 1;
    background-color: white;
    position: relative;
    overflow: scroll;

    &__info {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      align-items: center;
      justify-content: center;
      display: flex;
    }

    &__file_list {
      position: absolute;
      left: 0;
      right: 0;

      tbody {
        border-top: none !important;
      }

      tr {
        &.folder {
          .name a {
            text-decoration: none;
            color: #212529;
          }
        }

        &.folder_disabled {
          background-color: rgba(0, 0, 0, 0.1);
          pointer-events: none;

          .name a {
            text-decoration: none;
            color: #212529;
          }
        }
      }

      table {
        margin-bottom: 0px !important;
      }

      th {
        @extend .text-muted;
        padding: 0.75rem;
        font-weight: normal;
        border-bottom: 1px solid #dee2e6 !important;
      }

      td {
        height: 56px;
        vertical-align: middle;
        padding-right: 0.75rem;
        padding-left: 0.75rem;
        border-top: 1px solid #dee2e6;
        white-space: nowrap;

        &.name {
          max-width: 600px;
          overflow: auto;
          white-space: nowrap;
        }

        &.last-modified,
        &.size {
          @extend .text-muted;
        }

        &.tags {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
  }
}

.ged-tags {
  display: flex;
  align-items: center;

  .ged-tags-list {
    .remove-tag-button {
      cursor: pointer;
      position: relative;
      display: inline-flex;
      vertical-align: middle;

      .icon {
        font-size: 1.2em;
      }
    }
  }

  .ged-tags-add-button {
    display: inline-flex;

    td:hover &,
    &.show {
      .toggle-button {
        opacity: 100;
      }
    }

    align-items: flex-end;
    position: relative;

    .toggle-button {
      display: inline-flex;
      align-items: center;
      box-shadow: none;
      padding: 0;
      border: 0;
      opacity: 0;

      &:focus {
        //opacity: 100;
      }
    }
  }

  .ged-tags-menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 200px;
    padding: 12px;
  }
}
