.report-edition-form-header {
  align-items: center;
  display: flex;
  margin: 8px 14px;
}

.custom {
  margin-left: 1%;
  margin-right: 1%;
}

.custom-deep {
  margin-left: 1%;
  margin-right: 50%;
}

.title-button {
  margin-left: 10%;
}

.report-edition-form {
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;

  .badge-iqoa {
    position: absolute;
    right: 0;
    font-size: 1.1em;
  }

  h1 {
    text-align: center;
  }

  h5 {
    color: black;
  }

  .card {
    margin-bottom: 10px;

    &-header {
      top: 0;
      z-index: 2;
      background-color: #f5f6f8;
      position: -webkit-sticky;
      position: sticky;
    }
  }

  .form-col {
    padding: 0 5px 5px 0;
  }

  .flex-label {
    vertical-align: center;
    margin: 0;
    padding: 5px 5px 5px 0;
  }

  .flex-select {
    max-width: 90%;
  }

  .flex {
    display: flex;
  }

  .form-select {
    margin-right: 10px;
  }

  .small-date {
    max-width: 250px;
  }

  .card-body {
    padding: 1rem 1.5rem 1rem 1.5rem;
  }

  .add-button {
    display: inline-flex;

    .icon {
      margin-right: 3px;
    }
  }

  .add-sub-button {
    padding: 10px 10px 10px 0;
    display: inline-flex;
    text-decoration: none;

    .icon {
      margin-left: 3px;
    }
  }

  .section {
    position: relative;
    margin-bottom: 40px;
    padding-left: 15px;

    border-left: thick solid #e7e7e7;
  }

  .separator {
    border-top: 3px solid #bbb;
    margin: 20px 0 40px 0;
  }

  .remove-sub-button {
    color: red;
    // position: absolute;
    // top: -5px;
    // right: 0;
    display: inline-flex;

    .icon {
      margin-right: 3px;
    }
  }

  .remove-sub-files-button {
    color: red;
    position: absolute;
    top: -5px;
    right: 0;
    display: inline-flex;

    .icon {
      margin-right: 3px;
    }
  }

  .refresh-button {
    position: absolute;
    right: 0;
  }

  .accordion-body {
    padding: 0;
  }

  .remove-sub-button.flex-button {
    top: -35px;
  }

  .container-fluid {
    padding: 0;
  }

  .right-button {
    float: right;
  }

  .footer-buttons {
    margin-bottom: 10px;
  }

  .form-label {
    white-space: nowrap;
    margin: 0 4px 0 0;

    p {
      margin: 0;
      position: relative;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }

  .iqoa-select-container {
    width: 40%;
  }

  .dropzone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;

    p {
      margin: 0;
    }

    &:hover {
      border-color: #adacac;
    }
  }

  .loader-container {
    margin: 5px 0 5px 0;
    float: right;
  }

  .file.row {
    .card {
      text-align: center;

      -webkit-user-select: none;
      /* Safari */
      -moz-user-select: none;
      /* Firefox */
      -ms-user-select: none;
      /* IE10+/Edge */
      user-select: none;
      /* Standard */

      .card-title {
        font-size: 1em;
      }

      .status-icon-container {
        .icon {
          transform: scale(1.5);
        }

        .hover-on {
          display: none;
        }

        .hover-off {
          display: inline-block;
        }

        &:hover {
          .hover-on {
            display: inline-block;
          }

          .hover-off {
            display: none;
          }
        }
      }

      &:hover {
        color: black;
        border-color: black;
      }
    }

    .loader {
      width: 24px;
      height: 24px;
    }

    .not-clickable {
      cursor: context-menu;
    }
  }

  .clickable {
    cursor: pointer;
  }

  .ged-resources {
    width: 100%;
    text-align: center;
  }
}
