$side-bar-background-color: white;
$side-bar-item-color: #6c757d;
$side-bar-border: solid #e9e8e8 1px;

.side-bar {
  padding-top: 45px;
  height: 100%;

  border-top: $side-bar-border;
  border-right: $side-bar-border;

  background-color: $side-bar-background-color;

  .nav-item {
    .nav-link {
      transition: background-color 0.3s ease;
      &:hover {
        background-color: #f5f6f8;
      }
      height: 120px;
      width: 120px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $side-bar-item-color;

      &.active {
        background-color: #18833d;
        color: white;
      }

      .icon {
        font-size: 36px;
        margin-bottom: 6px;
      }

      .label {
        font-size: 0.85rem;
        text-align: center;
      }
    }
  }
}
