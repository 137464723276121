.report-consultation-header {
  align-items: center;
  display: flex;
  margin: 8px 14px;
}

.report-consultation {
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: justify;
  hyphens: auto;

  .text-user {
    white-space: pre-wrap;
  }

  h1 {
    text-align: center;
  }

  .card {
    margin-bottom: 10px;
  }

  .float-left {
    float: left;
  }

  // .accordion-body {
  // padding-right: 10px;
  // }

  .accordion {
    padding: 0;
  }

  .row {
    .col {
      padding: 5px 15px 5px 0;
      position: relative;
    }
  }

  .chronologie {
    height: 80px;
    position: relative;

    .dot {
      background: white;
      border: 1px solid gray;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      margin-left: auto;
      margin-right: auto;
    }

    .bar {
      position: absolute;
      height: 14px;
      width: 100%;
      top: 50%;
      border-radius: 10%;
      transform: translateY(-50%);
      background-color: #18833d;
    }

    .element {
      position: absolute;
      width: 150px;
      height: 70px;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
    }
  }

  .separator {
    border-top: 3px solid #bbb;
    margin: 20px 0 40px 0;
  }

  .section {
    position: relative;
    padding-left: 15px;
  }

  .badge-iqoa {
    position: absolute;
    right: 0;
    font-size: 1.1em;
  }
}
