.get-all-reports-view {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  &__header {
    align-items: center;
    display: flex;
    margin: 8px 14px;

    .create-report-button {
      margin-left: 5px;
      display: inline-flex;
      align-items: center;

      .icon {
        margin-right: 3px;
      }
    }
  }

  .reports {
    flex: 1;
    background-color: white;
    position: relative;
    overflow: scroll;

    &__list {
      position: absolute;
      left: 0;
      right: 0;

      table {
        margin-bottom: 0 !important;

        tbody {
          border-top: none !important;
        }

        th {
          @extend .text-muted;
          padding: 0.75rem;
          font-weight: normal;
          border-bottom: 1px solid #dee2e6 !important;
        }

        td {
          height: 56px;
          vertical-align: middle;
          padding-right: 0.75rem;
          padding-left: 0.75rem;
          border-top: 1px solid #dee2e6;
          white-space: nowrap;

          &.title {
            width: 20%;
          }

          &.indice {
            width: 5%;
          }

          &.type {
            width: 15%;
          }

          &.intervenants {
            width: 20%;
          }

          &.submittedPer {
            width: 20%;
          }

          &.is-submitted {
            color: $success;
            width: 3%;

            & > div {
              display: inline-flex;
              align-items: center;
              justify-content: center;
              height: 100%;
              width: 100%;
            }
          }

          &.due-date {
            &.late {
              color: $danger;
            }
          }

          &.submission-date {
            @extend .text-muted;
          }
        }

        .user-details {
          .user-avatar {
            height: 20px;
          }
        }
      }
    }
  }

  .reports-loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    display: flex;
  }
}

.report-creation-form {
  max-width: 600px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  margin-bottom: 50px;

  .card-body {
    padding: 4rem;
  }

  .blank-select {
    display: none;
  }
}

.report-creation-alerts {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column-reverse;
}
