.breadcrumb {
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
  margin-bottom: unset;
}

.breadcrumb-item {
  a {
    color: #6c757d;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
