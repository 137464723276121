$base-margin: 16px;

#model-viewer-container-hidden {
  visibility: collapse;
  width: 0%;
  height: 0%;
}

#model-viewer-container {
  width: 100%;
  height: 100%;
  position: relative;

  .model-viewer-info-container {
    position: absolute;
    height: 100%;
    padding: $base-margin;
    z-index: 2;
    opacity: 0.85;

    .icon {
      vertical-align: middle;
    }

    .model-viewer-info {
      width: 420px;
      height: 100%;

      .card-title {
        font-size: 1.5rem;
      }

      .card-body {
        overflow: auto;
      }

      .close-button {
        position: absolute;
        top: $base-margin;
        right: $base-margin;
      }

      .sections {
        display: flex;
        flex-direction: column;
        margin-top: 25px;

        .section {
          margin-bottom: 10px;

          .section-title {
            font-size: 1.1rem;
            margin: 0 -0.5rem 10px -0.5rem;
            background-color: #c9c9c94a;
            border-radius: 2px;
            padding: 2px 12px;
          }
        }
      }

      .ged-resources {
        display: inline-flex;
        flex-direction: column;
        width: 100%;
      }

      .report-excerpts {
        table-layout: fixed;

        .report-excerpt {
          td {
            white-space: nowrap;

            &.title {
              width: 65%;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            &.date {
              width: 30%;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            &.note {
              width: 5%;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            &.note {
              width: 50px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }

      .text-container h3 {
        margin-top: 16px;
        width: fit-content;
      }

      .text-content {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
      }

      .text-content img {
        display: block;
        max-width: 100%;
        width: auto;
        height: auto;
      }

      .text-content p {
        margin-right: 5px;
      }

      .markdown-text {
        overflow-wrap: break-word;
        width: 100%;
      }
    }
  }
}
