$base-margin: 10px;
$base-widget-width: 300px;

.dashboard-view {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  &__header {
    align-items: center;
    display: flex;
    margin: 8px 14px;
  }

  &__content {
    padding: $base-margin 2 * $base-margin;
    width: 100%;
    flex: 1;
    gap: $base-margin;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;

    .break {
      flex-basis: 100%;
      height: 0;
    }
  }
}

.dashboard-summary {
  display: flex;
  gap: $base-margin;
  &__section {
    width: $base-widget-width;

    &__list {
      @extend .card;
      height: 250px;
      overflow: auto;

      .list-group {
        .list-group-item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: unset;
          border-radius: unset;
        }
      }
    }
  }
}

.iqoa-notes-chart {
  width: 2 * $base-widget-width + $base-margin;
  height: 361px;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.model-details {
  width: $base-widget-width;
  height: 361px;

  .card-body {
    display: flex;
    flex-direction: column;
    gap: 6px;

    .comment {
      flex: 1 1 auto;
      overflow-y: auto;
      height: 0px;
    }
  }
}
