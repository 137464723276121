.top-bar {
  .inbox {
    margin-left: auto;
    margin-right: 20px;

    .toggle-button {
      display: inline-flex;
      position: relative;
      padding: 0;
      box-shadow: none;
      border: 0;

      .icon {
        font-size: 40px;
      }
    }

    .badge {
      position: absolute;
      bottom: 0;
      right: -8px;
    }
  }
}
