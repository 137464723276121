// Overrides

$font-size-base: 0.9rem;

// Dependencies
// WARNING: Order is important

@import "~bootstrap/scss/bootstrap";
@import "@aws-amplify/ui-react/styles.css";
@import "./amplify";
@import "./containers/HomeView/HomeView";
@import "./containers/GedView/GedView";
@import "./containers/GetAllReportsView/GetAllReportsView";
@import "./containers/DashboardView/DashboardView";
@import "./components/Breadcrumb/Breadcrumb";
@import "./components/Sidebar/Sidebar";

// Constants
$background-color: #f5f6f8;

html,
body,
#root,
#root > div {
  height: 100vh;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Montserrat Light", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $background-color;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
